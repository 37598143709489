export default [
  // Statistik
  {
    path: "/statistik",
    name: "statistik",
    component: () => import("@/views/pages/statistik/Index.vue"),
    meta: {
      role: "Siswa",
      authRequired: true,
      pageTitle: "Statistik",
      breadcrumb: [
        {
          text: "Statistik",
          active: true,
        },
      ],
    },
  },
  {
    path: "/statistik-tryout",
    name: "statistik-tryout",
    component: () => import("@/views/pages/statistik/components/Tryout.vue"),
    meta: {
      role: "Siswa",
      authRequired: true,
      pageTitle: "Statistik ",
      breadcrumb: [
        {
          text: "Statistik ",
          active: true,
        },
      ],
    },
  },
  {
    path: "/statistik-tryout/:id",
    name: "statistik-tryout-detail",
    component: () => import("@/views/pages/statistik/Detail.vue"),
    meta: {
      role: "Siswa",
      authRequired: true,
      pageTitle: "Detail",
      breadcrumb: [
        {
          text: "Statistik",
          to: "/statistik",
        },
        {
          text: "Detail",
          active: true,
        },
      ],
    },
  },

  // Tryout
  {
    path: "/tryout",
    name: "tryout",
    component: () => import("@/views/pages/tryout/Index.vue"),
    meta: {
      role: "Siswa",
      authRequired: true,
      pageTitle: "Tryout",
      breadcrumb: [
        {
          text: "Tryout",
          active: true,
        },
      ],
    },
  },
  {
    path: "/tryout-blank",
    name: "tryout-blank",
    component: () => import("@/views/pages/tryout/components/Blank.vue"),
    meta: {
      role: "Siswa",
      authRequired: true,
      pageTitle: "Tryout",
      breadcrumb: [
        {
          text: "Tryout",
          active: true,
        },
      ],
    },
  },
  {
    path: "/tryout-selesai",
    name: "tryout-selesai",
    component: () => import("@/views/pages/tryout/components/Selesai.vue"),
    meta: {
      role: "Siswa",
      authRequired: true,
      pageTitle: "Tryout",
      breadcrumb: [
        {
          text: "Tryout",
          active: true,
        },
      ],
    },
  },
  {
    path: "/tryout-terdaftar/:username/:nama_paket/:mulai/:selesai",
    name: "tryout-terdaftar",
    component: () => import("@/views/pages/tryout/components/Terdaftar.vue"),
    meta: {
      role: "Siswa",
      authRequired: true,
      pageTitle: "Tryout",
      breadcrumb: [
        {
          text: "Tryout",
          active: true,
        },
      ],
    },
  },
  {
    path: "/tryout-detail",
    name: "tryout-detail",
    component: () => import("@/views/pages/tryout/Detail.vue"),
    meta: {
      role: "Siswa",
      authRequired: true,
      pageTitle: "Tryout Detail",
      breadcrumb: [
        {
          text: "Tryout",
          to: "/tryout",
        },
        {
          text: "Detail",
          active: true,
        },
      ],
    },
  },
  {
    path: "/tryout-detail-subtest",
    name: "tryout-detail-subtest",
    component: () => import("@/views/pages/tryout/DetailSubtes.vue"),
    meta: {
      role: "Siswa",
      authRequired: true,
      pageTitle: "Tryout Detail Subtes",
      breadcrumb: [
        {
          text: "Tryout",
          to: "/tryout",
        },
        {
          text: "Detail Subtes",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pilih-rumpun",
    name: "pilih-rumpun",
    component: () => import("@/views/pages/tryout/PilihRumpun.vue"),
    meta: {
      role: "Siswa",
      authRequired: true,
      pageTitle: "Pilih Rumpun",
      breadcrumb: [
        {
          text: "Tryout",
          to: "/tryout",
        },
        {
          text: "Pilih Rumpun",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pilih-sekolah",
    name: "pilih-sekolah",
    component: () => import("@/views/pages/tryout/PilihSekolah.vue"),
    meta: {
      role: "Siswa",
      authRequired: true,
      pageTitle: "Pilih Sekolah",
      breadcrumb: [
        {
          text: "Tryout",
          to: "/tryout",
        },
        {
          text: "Pilih Sekolah",
          active: true,
        },
      ],
    },
  },
  {
    path: "/raport",
    name: "raport",
    component: () => import("@/views/pages/raport/Index.vue"),
    meta: {
      role: "Siswa",
      authRequired: true,
      pageTitle: "Raport",
      breadcrumb: [
        {
          text: "Raport",
          active: true,
        },
      ],
    },
  },
  {
    path: "/profil",
    name: "profil",
    component: () => import("@/views/pages/profil/Index.vue"),
    meta: {
      role: "Siswa",
      authRequired: true,
      pageTitle: "Profil",
      breadcrumb: [
        {
          text: "Profil",
          active: true,
        },
      ],
    },
  },

  // E-book
  {
    path: "/ebook",
    name: "ebook",
    component: () => import("@/views/pages/ebook/Index.vue"),
    meta: {
      role: "Siswa",
      authRequired: true,
      pageTitle: "E-book",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "E-book",
          active: true,
        },
      ],
    },
  },
  {
    path: "/ebook-banyak-dicari",
    name: "ebook-banyak-dicari",
    component: () => import("@/views/pages/ebook/LotsSearch.vue"),
    meta: {
      role: "Siswa",
      authRequired: true,
      pageTitle: "E-book Paling Banyak Dicari",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "E-book",
          to: "/ebook",
        },
        {
          text: "E-book banyak dicari",
          active: true,
        },
      ],
    },
  },
  {
    path: "/ebook-gratis",
    name: "ebook-gratis",
    component: () => import("@/views/pages/ebook/Free.vue"),
    meta: {
      role: "Siswa",
      authRequired: true,
      pageTitle: "E-book Gratis",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "E-book",
          to: "/ebook",
        },
        {
          text: "E-book Gratis",
          active: true,
        },
      ],
    },
  },
  {
    path: "/ebook/detail/:slug/:status",
    name: "ebook-detail",
    component: () => import("@/views/pages/ebook/Detail.vue"),
    meta: {
      role: "Siswa",
      authRequired: true,
      pageTitle: "E-book Detail",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "E-book",
          to: "/ebook",
        },
        {
          text: "E-book Detail",
          active: true,
        },
      ],
    },
  },
  {
    path: "/paket_tryout",
    name: "paket_tryout",
    component: () => import("@/views/pages/paket-tryout/Index.vue"),
    meta: {
      role: "Siswa",
      authRequired: true,
      pageTitle: "Paket Tryout",
      breadcrumb: [
        {
          text: "Paket Tryout",
          active: true,
        },
      ],
    },
  },
  {
    path: "/billing",
    name: "billing",
    component: () => import("@/views/pages/billing/Index.vue"),
    meta: {
      role: "Siswa",
      authRequired: true,
      pageTitle: "Billing",
      breadcrumb: [
        {
          text: "Billing",
          active: true,
        },
      ],
    },
  },
  {
    path: "/billing-detail",
    name: "billing-detail",
    component: () => import("@/views/pages/billing/Detail.vue"),
    meta: {
      role: "Siswa",
      authRequired: true,
      pageTitle: "Detail Billing",
      breadcrumb: [
        {
          text: "Billing",
          to: "/billing",
        },
        {
          text: "Detail Billing",
          active: true,
        },
      ],
    },
  },
  {
    path: "/billing-detail-invoice/:transaksi_id",
    name: "billing-detail-invoice",
    component: () => import("@/views/pages/billing/DetailInvoice.vue"),
    meta: {
      role: "Siswa",
      authRequired: true,
      pageTitle: "Detail Billing",
      breadcrumb: [
        {
          text: "Billing",
          to: "/billing",
        },
        {
          text: "Detail Invoice",
          active: true,
        },
      ],
    },
  },
  {
    path: "/panduan",
    name: "panduan",
    component: () => import("@/views/pages/panduan/Index.vue"),
    meta: {
      role: "Siswa",
      authRequired: true,
      pageTitle: "Panduan",
      breadcrumb: [
        {
          text: "Panduan",
          active: true,
        },
      ],
    },
  },
  {
    path: "/faq",
    name: "faq",
    component: () => import("@/views/pages/faq/Faq.vue"),
    meta: {
      role: "Siswa",
      authRequired: true,
      pageTitle: "FAQ",
      breadcrumb: [
        {
          text: "FAQ",
          active: true,
        },
      ],
    },
  },
  // {
  //   path: "/main-ujian",
  //   name: "main-ujian",
  //   component: () => import("@/views/pages/ujian/index.vue"),
  //   meta: {
  //     layout: "full",
  //     role: "Siswa",
  //     authRequired: true,
  //   },
  // },

  {
    path: "/snbp",
    name: "snbp",
    component: () => import("@/views/pages/snbp/Index.vue"),
    meta: {
      role: "Siswa",
      authRequired: true,
      pageTitle: "SNBP",
      breadcrumb: [
        {
          text: "SNBP",
          active: true,
        },
      ],
    },
  },
  {
    path: "/snbp-detail",
    name: "snbp-detail",
    component: () => import("@/views/pages/snbp/Detail.vue"),
    meta: {
      role: "Siswa",
      authRequired: true,
      pageTitle: "SNBP Detail",
      breadcrumb: [
        {
          text: "SNBP",
          to: "/snbp",
        },
        {
          text: "SNBP Detail",
          active: true,
        },
      ],
    },
  },
  {
    path: "/snbp/daftar",
    name: "snbp-daftar",
    component: () => import("@/views/pages/snbp/Daftar.vue"),
    meta: {
      role: "Siswa",
      authRequired: true,
      pageTitle: "SNBP Daftar",
      breadcrumb: [
        {
          text: "SNBP",
          to: "/snbp",
        },
        {
          text: "Pembayaran SNBP",
          to: "/payment-snbp",
        },
        {
          text: "SNBP Daftar",
          active: true,
        },
      ],
    },
  },
  {
    path: "/payment-snbp",
    name: "payment-snbp",
    component: () => import("@/views/pages/snbp/Payment.vue"),
    meta: {
      role: "Siswa",
      authRequired: true,
      pageTitle: "Pembayaran SNBP",
      breadcrumb: [
        {
          text: "SNBP",
          to: "/snbp",
        },
        {
          text: "Pembayaran SNBP",
          active: true,
        },
      ],
    },
  },
  {
    path: "/payment-snbp-confirmation/:id",
    name: "payment-snbp-confirmation",
    component: () => import("@/views/pages/snbp/Konfirmasi-Pembayaran.vue"),
    meta: {
      role: "Siswa",
      authRequired: true,
      pageTitle: "Konfirmasi Pembayaran SNBP",
      breadcrumb: [
        {
          text: "SNBP",
          to: "/snbp",
        },
        {
          text: "Konfirmasi Pembayaran SNBP",
          active: true,
        },
      ],
    },
  },
  {
    path: "/main-ujian",
    name: "main-ujian",
    component: () => import("@/views/pages/ujian/Main.vue"),
    meta: {
      layout: "full",
      role: "Siswa",
      authRequired: true,
    },
  },
  {
    path: "/hasil-ujian",
    name: "hasil-ujian",
    component: () => import("@/views/pages/ujian/HasilUjian.vue"),
    meta: {
      layout: "full",
      role: "Siswa",
      authRequired: true,
    },
  },
  {
    path: "/hasil-rasionalisasi",
    name: "hasil-rasionalisasi",
    component: () => import("@/views/pages/ujian/HasilRasionalisasi.vue"),
    meta: {
      layout: "full",
      role: "Siswa",
      authRequired: true,
    },
  },
  {
    path: "/ranking-siswa-tryout",
    name: "ranking-siswa-tryout",
    component: () => import("@/views/pages/ujian/Ranking.vue"),
    meta: {
      layout: "full",
      role: "Siswa",
      authRequired: true,
    },
  },
  {
    path: "/pembahasan-soal-tryout",
    name: "pembahasan-soal-tryout",
    component: () => import("@/views/pages/ujian/Pembahasan.vue"),
    meta: {
      layout: "full",
      role: "Siswa",
      authRequired: true,
    },
  },
];
