import Vue from "vue";
import Vuex from "vuex";

// Modules
import ecommerceStoreModule from "@/views/apps/e-commerce/eCommerceStoreModule";
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import auth from "./auth";
import authAdmin from "./auth-admin";
import kategoriArtikel from "./kategoriArtikel";
import artikel from "./artikel";
import authUserAdmin from "./auth-user-admin";
import faq from "./faq";
import panduan from "./panduan";
import banner from "./banner";
import rumpun from "./rumpun";
import kabupaten_kota from "./kabupaten_kota";
import provinsi from "./provinsi";
import paketTryout from "./paket";
import roleManagement from "./role-management";
import level from "./level";
import menu from "./menu";
import pengguna from "./pengguna";
import eBook from "./e-book";
import masterUniv from "./masteruniv";
import mastersekolah from "./mastersekolah";
import jurusan from "./jurusan";
import mapel from "./mapel";
import tryout from "./tryout";
import voucher from "./voucher";
import payment from "./payment";
import rekening from "./rekening";
import ujian from "./ujian";
import jadwaladmin from "./jadwal-admin";
import createPersistedState from "vuex-persistedstate";
import transaksiManual from "./transaksiManual";
import banksoal from "./banksoal";

import ebook from "./ebook";
import transaksi from "./transaksi";
import kategoriUjian from "./kategoriUjian";
import adminujian from "./ujian-admin";
import snbp from "./snbp";
import raport from "./raport";
import grafik from "./grafik";
import rasionalisasi from "./rasionalisasi";
import sekolah from "./sekolah";
import ranking from "./ranking";
import dashboard from "./dashboard";
import hasil from "./hasil";
import notifikasi from "./notifikasi";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    dashboard,
    ranking,
    app,
    appConfig,
    verticalMenu,
    artikel,
    kategoriArtikel,
    auth,
    "auth-admin": authAdmin,
    "auth-user-admin": authUserAdmin,
    "app-ecommerce": ecommerceStoreModule,
    faq,
    panduan,
    banner,
    kabupaten_kota,
    rumpun,
    paketTryout,
    "role-management": roleManagement,
    level,
    menu,
    pengguna,
    "e-book": eBook,
    masterUniv,
    jurusan,
    mapel,
    tryout,
    voucher,
    payment,
    ebook,
    transaksi,
    rekening,
    ujian,
    transaksiManual,
    banksoal,
    kategoriUjian,
    adminujian,
    jadwaladmin,
    snbp,
    raport,
    grafik,
    rasionalisasi,
    sekolah,
    provinsi,
    hasil,
    notifikasi,
    mastersekolah,
  },
  strict: process.env.DEV,
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
});
