export default [
  {
    path: "/login",
    name: "auth-login",
    component: () => import("@/views/pages/authentication/Login.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/auth-ou/v-my-acc/:token",
    name: "auth-verifikasi-email",
    component: () => import("@/views/pages/authentication/VerifikasiEmail.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/register",
    name: "auth-register",
    component: () => import("@/views/pages/authentication/Register.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/error/Error404.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      action: "read",
    },
  },
  {
    path: "/forgot-password",
    name: "auth-forgot-password",
    component: () => import("@/views/pages/authentication/ForgotPassword.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/ft-pw/:token",
    name: "reset-password",
    component: () => import("@/views/pages/authentication/ResetPassword.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
    },
  },
  {
    path: "/ft-pw-admin/:token",
    name: "reset-password-admin",
    component: () => import("@/views/pages/authentication/ResetPassword.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
    },
  },
  {
    path: "/pages/miscellaneous/coming-soon",
    name: "misc-coming-soon",
    component: () => import("@/views/pages/miscellaneous/ComingSoon.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/not-authorized",
    name: "misc-not-authorized",
    component: () => import("@/views/pages/miscellaneous/NotAuthorized.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
    },
  },
  {
    path: "/pages/miscellaneous/under-maintenance",
    name: "misc-under-maintenance",
    component: () => import("@/views/pages/miscellaneous/UnderMaintenance.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/miscellaneous/error",
    name: "misc-error",
    component: () => import("@/views/pages/miscellaneous/Error.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/faq",
    name: "pages-faq",
    component: () => import("@/views/pages/faq/Faq.vue"),
    meta: {
      pageTitle: "FAQ",
      breadcrumb: [
        {
          text: "Pages",
        },
        {
          text: "FAQ",
          active: true,
        },
      ],
    },
  },
];
