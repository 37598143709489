import service from "@/service";
import axios from "@/libs/axios";
import { secretKey, secretFile } from "@/auth/secretKey";

// Module Vuex akun
export default {
  namespaced: true,
  state: {
    detail: {},
    datas: [],
    token: null,
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data;
    },
    SET_BEARER(state, accessToken) {
      axios.defaults.headers.common["Author"] = `Bearer ${accessToken}`;
    },
    SET_DETAIL(state, data) {
      state.detail = data;
    },
  },
  getters: {
    detailEbook(state) {
      return state.detail;
    },
  },
  actions: {
    async downloadFile({}, payload = {}) {
      try {
        const apiFile = process.env.VUE_APP_API_FILE || "https://file.elp-dev.my.id";
        const config = {
          method: "post",
          url: `${apiFile}/download`,
          data: payload,
          headers: {
            secret: secretFile,
          },
          responseType: "blob",
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async index({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/ebook`,
          params,
          headers: {
            secret: secretKey,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async detail({ commit }, id) {
      try {
        let config = {
          method: "get",
          url: `/ebook/${id}`,
          headers: {
            secret: secretKey,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async createUpdateDelete({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/input/ebook",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async indexKategoriEbook({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/kategori-ebook`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async indexIdKategoriEbook({}, id) {
      try {
        let config = {
          method: "get",
          url: `/kategori-ebook/${id}`,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async CUDKategoriEbook({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/input/kategori-ebook",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async ebookSaya({}, params) {
      try {
        let config = {
          method: "get",
          url: `/my-ebook`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async indexVoucherEbook({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/voucher-ebook`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async indexIdVoucherEbook({}, id) {
      try {
        let config = {
          method: "get",
          url: `/voucher-ebook/${id}`,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async CUDVoucherEbook({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/input/voucher-ebook",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async saveEbookGratis({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/save-ebook",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async checkout({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/checkout-paket",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async ebookBuyer({}, params) {
      try {
        let config = {
          method: "get",
          url: `/ebook-buyer`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
  },
};
