export default [
  {
    path: "/",
    name: "user-admin-login",
    component: () => import("@/views/pages/user-admin/auth/Login.vue"),
    meta: {
      layout: "full",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "forgot-password",
    name: "user-admin-forgot-password",
    component: () => import("@/views/pages/user-admin/auth/ForgotPassword.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "reset-password",
    name: "user-admin-reset-password",
    component: () => import("@/views/pages/user-admin/auth/ResetPassword.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "dashboard",
    name: "user-admin-dashboard",
    component: () => import("@/views/pages/user-admin/dashboard/Index.vue"),
    meta: {
      role: "Super Admin",
      authRequired: true,
      pageTitle: "Dashboard",
      breadcrumb: [
        {
          text: "Dashboard",
          active: true,
        },
      ],
    },
  },
  {
    path: "pengguna",
    name: "user-admin-pengguna",
    component: () => import("@/views/pages/user-admin/pengguna/Index.vue"),
    meta: {
      role: "Super Admin",
      authRequired: true,
      pageTitle: "Pengguna",
      breadcrumb: [
        {
          text: "Pengguna",
          active: true,
        },
      ],
    },
  },
  {
    path: "payment",
    name: "user-admin-payment",
    component: () => import("@/views/pages/user-admin/payment/Index.vue"),
    meta: {
      role: "Super Admin",
      authRequired: true,
      pageTitle: "Payment",
      breadcrumb: [
        {
          text: "Payment",
          active: true,
        },
      ],
    },
  },
  {
    path: "tryout",
    name: "user-admin-tryout",
    component: () => import("@/views/pages/user-admin/tryout/Index.vue"),
    meta: {
      role: "Super Admin",
      authRequired: true,
      pageTitle: "Tryout",
      breadcrumb: [
        {
          text: "Tryout",
          active: true,
        },
      ],
    },
  },
  {
    path: "paket-tryout",
    name: "user-admin-paket-tryout",
    component: () => import("@/views/pages/user-admin/paket-tryout/Index.vue"),
    meta: {
      role: "Super Admin",
      authRequired: true,
      pageTitle: "Paket Tryout",
      breadcrumb: [
        {
          text: "Paket Tryout",
          active: true,
        },
      ],
    },
  },
  {
    path: "master-universitas",
    name: "user-admin-universitas",
    component: () =>
      import("@/views/pages/user-admin/master-universitas/Index.vue"),
    meta: {
      role: "Super Admin",
      authRequired: true,
      pageTitle: "Master Universitas",
      breadcrumb: [
        {
          text: "Master Universitas",
          active: true,
        },
      ],
    },
  },
  {
    path: "ebook",
    name: "user-admin-ebook",
    component: () => import("@/views/pages/user-admin/ebook/Index.vue"),
    meta: {
      role: "Super Admin",
      authRequired: true,
      pageTitle: "Ebook",
      breadcrumb: [
        {
          text: "Ebook",
          active: true,
        },
      ],
    },
  },
  {
    path: "kategori-artikel",
    name: "user-admin-kategori-artikel",
    component: () =>
      import("@/views/pages/user-admin/kategori-artikel/Index.vue"),
    meta: {
      role: "Super Admin",
      authRequired: true,
      pageTitle: "Kategori Artikel",
      breadcrumb: [
        {
          text: "Kategori Artikel",
          active: true,
        },
      ],
    },
  },
  {
    path: "artikel",
    name: "user-admin-artikel",
    component: () => import("@/views/pages/user-admin/artikel/Index.vue"),
    meta: {
      role: "Super Admin",
      authRequired: true,
      pageTitle: "Artikel",
      breadcrumb: [
        {
          text: "Artikel",
          active: true,
        },
      ],
    },
  },
  {
    path: "smbp",
    name: "user-admin-smbp",
    component: () => import("@/views/pages/user-admin/smbp/Index.vue"),
    meta: {
      role: "Super Admin",
      authRequired: true,
      pageTitle: "SMBP",
      breadcrumb: [
        {
          text: "SMBP",
          active: true,
        },
      ],
    },
  },
  {
    path: "role-management",
    name: "user-admin-role-management",
    component: () =>
      import("@/views/pages/user-admin/role-management/Index.vue"),
    meta: {
      role: "Super Admin",
      authRequired: true,
      pageTitle: "Role Management",
      breadcrumb: [
        {
          text: "Role Management",
          active: true,
        },
      ],
    },
  },
];
