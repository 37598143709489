import service from "@/service";
import axios from "@/libs/axios";
import { secretKey } from "@/auth/secretKey";

// Module Vuex akun
export default {
  namespaced: true,
  state: {
    total_harga: {},
    detail: {},
    datas: [],
    token: null,
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data;
    },
    SET_BEARER(state, accessToken) {
      axios.defaults.headers.common["Author"] = `Bearer ${accessToken}`;
    },
    SET_DETAIL(state, data) {
      state.detail = data;
    },
    SET_TOTAL_HARGA(state, data) {
      state.total_harga = data;
    },
  },
  actions: {
    async publicIndex({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/paket`,
          params,
          headers: {
            secret: secretKey,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async index({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/paket`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async detail({ commit }, { id, jadwal_id }) {
      try {
        let config = {
          method: "get",
          url: `/paket/${id}`,
          headers: {
            secret: secretKey,
          },
        };

        if (jadwal_id) {
          config.params = { jadwal_id };
        }
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async createUpdateDelete({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/input/paket",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async indexId({}, id) {
      try {
        let config = {
          method: "get",
          url: `/paket/${id}`,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async getSubcat({}, params = {}) {
      try {
        let config = {
          method: "get",
          url: `/subcat-paket`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async pSubtest({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/p-subtest-paket`,
          params,
          headers: {
            secret: secretKey,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async indexFaqPaket({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/faq-paket`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async indexIdFaqPaket({}, id) {
      try {
        let config = {
          method: "get",
          url: `/faq-paket/${id}`,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async CUDFaqPaket({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/input/faq-paket",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async indexSubtestPaket({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/detail-paket`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async indexIdSubtestPaket({}, id) {
      try {
        let config = {
          method: "get",
          url: `/detail-paket/${id}`,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async CUDSubtestPaket({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/input/detail-paket",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async indexKeunggulanPaket({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/keunggulan-paket`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async indexIdKeunggulanPaket({}, id) {
      try {
        let config = {
          method: "get",
          url: `/keunggulan-paket/${id}`,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async CUDKeunggulanPaket({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/input/keunggulan-paket",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async indexKategoriPaket({ commit }, params = {}) {
      try {
        let config = {
          method: "get",
          url: `/kategori-paket`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async indexIdKategoriPaket({}, id) {
      try {
        let config = {
          method: "get",
          url: `/kategori-paket/${id}`,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async CUDKategoriPaket({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/input/kategori-paket",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async indexVoucherPaket({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/voucher-paket`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async indexIdVoucherPaket({}, id) {
      try {
        let config = {
          method: "get",
          url: `/voucher-paket/${id}`,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async CUDVoucherPaket({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/input/voucher-paket",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async checkout({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/checkout-paket",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async indexMemberPaket({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/member-paket`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async cekPurchase({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/check-purchase-paket`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);

        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async storeMemberPaket({}, payload) {
      try {
        const config = {
          method: "post",
          url: "/input/member-paket",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response.data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async indexJadwal({}, params = {}) {
      try {
        const config = {
          method: "get",
          url: "/jadwal-paket",
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async indexJadwalId({}, id) {
      try {
        const config = {
          method: "get",
          url: `/jadwal-paket/${id}`,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async storeJadwal({}, payload) {
      try {
        const config = {
          method: "post",
          url: "/input/jadwal-paket",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response.data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async jadwalSaya({}, params = {}) {
      try {
        const config = {
          method: "get",
          url: "/my-jadwal",
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
  },
};
