import service from "@/service";
import axios from "@/libs/axios";
import { secretKey } from "@/auth/secretKey";

// Module Vuex akun
export default {
  namespaced: true,
  state: {
    datas: [],
    token: null,
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data;
    },
    SET_BEARER(state, accessToken) {
      axios.defaults.headers.common["Author"] = `Bearer ${accessToken}`;
    },
  },
  actions: {
    async publicIndex({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/transaksi-manual`,
          params,
          headers: {
            secret: secretKey,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async indexId({}, id) {
      try {
        let config = {
          method: "get",
          url: `/transaksi-manual/${id}`,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async index({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `/transaksi-manual`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async publicDetail({ commit }, id) {
      try {
        let config = {
          method: "get",
          url: `/transaksi-manual/${id}`,
          params,
          headers: {
            secret: secretKey,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async createUpdateDelete({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/input/transaksi-manual",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async CancelOrder({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/cancel-order",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async confirmPayment({ commit }, id) {
      try {
        let config = {
          method: "post",
          url: `/manual-transfer/mark-paid-off/${id}`,
          // params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async deleteBuktiTF({ commit }, params) {
      try {
        let config = {
          method: "post",
          url: "/manual-transfer/rm-evidence",
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
  },
};
