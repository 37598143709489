export default [
  // *===============================================---*
  // *--------- Home Login -------------------------------*
  // *===============================================---*
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/home-login/Index.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
    },
  },
  {
    path: "/ebook/category/:id",
    name: "ebook-by-category",
    component: () => import("@/views/home-login/EbookByCategory.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
    },
  },
  {
    path: "/paket-tryout",
    name: "paket-tryout",
    component: () => import("@/views/home-login/Paket-Tryout.vue"),
    meta: {
      layout: "full",
      // redirectIfLoggedIn: true,
      resource: "Auth",
    },
  },
  {
    path: "/paket-selengkapnya",
    name: "paket-selengkapnya",
    component: () => import("@/views/home-login/Paket-selengkapnya.vue"),
    meta: {
      layout: "full",
      // redirectIfLoggedIn: true,
      resource: "Auth",
    },
  },
  {
    path: "/artikel",
    name: "artikel",
    component: () => import("@/views/home-login/Artikel.vue"),
    meta: {
      layout: "full",
      // redirectIfLoggedIn: true,
      resource: "Auth",
    },
  },
  {
    path: "/artikel/detail/:slug",
    name: "artikel-detail",
    component: () => import("@/views/home-login/ArtikelDetail.vue"),
    meta: {
      layout: "full",
      // redirectIfLoggedIn: true,
      resource: "Auth",
    },
  },
  {
    path: "/artikel/edit/:id",
    name: "artikel-edit",
    component: () => import("@/views/artikel/BlogEdit.vue"),
    meta: {
      layout: "full",
      // redirectIfLoggedIn: true,
      resource: "Auth",
    },
  },
  {
    path: "/tentang-kami",
    name: "tentang-kami",
    component: () => import("@/views/home-login/Tentang-kami.vue"),
    meta: {
      layout: "full",
      // redirectIfLoggedIn: true,
      resource: "Auth",
    },
  },
  {
    path: "/kebijakan-privasi",
    name: "kebijakan-privasi",
    component: () => import("@/views/home-login/Kebijakan-privasi.vue"),
    meta: {
      layout: "full",
      // redirectIfLoggedIn: true,
      resource: "Auth",
    },
  },
  {
    path: "/syarat-ketentuan",
    name: "syarat-ketentuan",
    component: () => import("@/views/home-login/Syarat-ketentuan.vue"),
    meta: {
      layout: "full",
      // redirectIfLoggedIn: true,
      resource: "Auth",
    },
  },
  {
    path: "/paket-detail/:id",
    name: "paket-detail",
    component: () => import("@/views/home-login/Paket-Detail.vue"),
    meta: {
      layout: "full",
      // redirectIfLoggedIn: true,
      resource: "Auth",
    },
  },
  {
    path: "/e-book",
    name: "e-book",
    component: () => import("@/views/home-login/E-Book.vue"),
    meta: {
      layout: "full",
      // redirectIfLoggedIn: true,
      resource: "Auth",
    },
  },
  {
    path: "/e-book-banyak-dicari",
    name: "e-book-banyak-dicari",
    component: () => import("@/views/home-login/E-Book-Banyak-Dicari.vue"),
    meta: {
      layout: "full",
      // redirectIfLoggedIn: true,
      resource: "Auth",
    },
  },
  {
    path: "/e-book-gratis",
    name: "e-book-gratis",
    component: () => import("@/views/home-login/E-Book-Gratis.vue"),
    meta: {
      layout: "full",
      // redirectIfLoggedIn: true,
      resource: "Auth",
    },
  },
  {
    path: "/e-book-detail/:id",
    name: "e-book-detail",
    component: () => import("@/views/home-login/E-Book-Detail.vue"),
    meta: {
      layout: "full",
      // redirectIfLoggedIn: true,
      resource: "Auth",
    },
  },
  {
    path: "/payment/:id",
    name: "payment",
    component: () => import("@/views/payment/Index.vue"),
    meta: {
      layout: "full",
      // redirectIfLoggedIn: true,
      resource: "Auth",
    },
  },
  {
    path: "/payment-confirmation/:id",
    name: "payment-confirmation",
    component: () => import("@/views/payment/Konfirmasi.vue"),
    meta: {
      layout: "full",
      // redirectIfLoggedIn: true,
      resource: "Auth",
    },
  },

  {
    path: "/payment-ebook/:id",
    name: "payment-ebook",
    component: () => import("@/views/payment-ebook/Index.vue"),
    meta: {
      layout: "full",
      // redirectIfLoggedIn: true,
      resource: "Auth",
    },
  },
  {
    path: "/payment-ebook-confirmation/:id",
    name: "payment-ebook-confirmation",
    component: () => import("@/views/payment-ebook/Konfirmasi.vue"),
    meta: {
      layout: "full",
      // redirectIfLoggedIn: true,
      resource: "Auth",
    },
  },
  {
    path: "/apps/email",
    name: "apps-email",
    component: () => import("@/views/apps/email/Email.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "email-application",
    },
  },
  {
    path: "/apps/email/:folder",
    name: "apps-email-folder",
    component: () => import("@/views/apps/email/Email.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "email-application",
      navActiveLink: "apps-email",
    },
    beforeEnter(to, _, next) {
      if (
        ["sent", "draft", "starred", "spam", "trash"].includes(to.params.folder)
      )
        next();
      else next({ name: "error-404" });
    },
  },
  {
    path: "/apps/email/label/:label",
    name: "apps-email-label",
    component: () => import("@/views/apps/email/Email.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "email-application",
      navActiveLink: "apps-email",
    },
    beforeEnter(to, _, next) {
      if (
        ["personal", "company", "important", "private"].includes(
          to.params.label
        )
      )
        next();
      else next({ name: "error-404" });
    },
  },

  // *===============================================---*
  // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/todo",
    name: "apps-todo",
    component: () => import("@/views/apps/todo/Todo.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "todo-application",
    },
  },
  {
    path: "/apps/todo/:filter",
    name: "apps-todo-filter",
    component: () => import("@/views/apps/todo/Todo.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "todo-application",
      navActiveLink: "apps-todo",
    },
    beforeEnter(to, _, next) {
      if (["important", "completed", "deleted"].includes(to.params.filter))
        next();
      else next({ name: "error-404" });
    },
  },
  {
    path: "/apps/todo/tag/:tag",
    name: "apps-todo-tag",
    component: () => import("@/views/apps/todo/Todo.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "todo-application",
      navActiveLink: "apps-todo",
    },
    beforeEnter(to, _, next) {
      if (["team", "low", "medium", "high", "update"].includes(to.params.tag))
        next();
      else next({ name: "error-404" });
    },
  },

  // *===============================================---*
  // *--------- CHAT  ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/chat",
    name: "apps-chat",
    component: () => import("@/views/apps/chat/Chat.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "chat-application",
    },
  },

  // *===============================================---*
  // *--------- ECOMMERCE  ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/e-commerce/shop",
    name: "apps-e-commerce-shop",
    component: () =>
      import("@/views/apps/e-commerce/e-commerce-shop/ECommerceShop.vue"),
    meta: {
      contentRenderer: "sidebar-left-detached",
      contentClass: "ecommerce-application",
      pageTitle: "Shop",
      breadcrumb: [
        {
          text: "ECommerce",
        },
        {
          text: "Shop",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/e-commerce/wishlist",
    name: "apps-e-commerce-wishlist",
    component: () =>
      import(
        "@/views/apps/e-commerce/e-commerce-wishlist/ECommerceWishlist.vue"
      ),
    meta: {
      pageTitle: "Wishlist",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "ECommerce",
        },
        {
          text: "Wishlist",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/e-commerce/checkout",
    name: "apps-e-commerce-checkout",
    component: () =>
      import(
        "@/views/apps/e-commerce/e-commerce-checkout/ECommerceCheckout.vue"
      ),
    meta: {
      pageTitle: "Checkout",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "ECommerce",
        },
        {
          text: "Checkout",
          active: true,
        },
      ],
    },
  },
  {
    path: "/apps/e-commerce/:slug",
    name: "apps-e-commerce-product-details",
    component: () =>
      import(
        "@/views/apps/e-commerce/e-commerce-product-details/ECommerceProductDetails.vue"
      ),
    meta: {
      pageTitle: "Product Details",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "ECommerce",
        },
        {
          text: "Shop",
          active: true,
        },
        {
          text: "Product Details",
          active: true,
        },
      ],
    },
  },

  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/users/list",
    name: "apps-users-list",
    component: () => import("@/views/apps/user/users-list/UsersList.vue"),
  },
  {
    path: "/apps/users/view/:id",
    name: "apps-users-view",
    component: () => import("@/views/apps/user/users-view/UsersView.vue"),
  },
  {
    path: "/apps/users/edit/:id",
    name: "apps-users-edit",
    component: () => import("@/views/apps/user/users-edit/UsersEdit.vue"),
  },

  // Invoice
  {
    path: "/apps/invoice/list",
    name: "apps-invoice-list",
    component: () =>
      import("@/views/apps/invoice/invoice-list/InvoiceList.vue"),
  },
  {
    path: "/apps/invoice/preview/:id",
    name: "apps-invoice-preview",
    component: () =>
      import("@/views/apps/invoice/invoice-preview/InvoicePreview.vue"),
  },
  {
    path: "/apps/invoice/add/",
    name: "apps-invoice-add",
    component: () => import("@/views/apps/invoice/invoice-add/InvoiceAdd.vue"),
  },
  {
    path: "/apps/invoice/edit/:id",
    name: "apps-invoice-edit",
    component: () =>
      import("@/views/apps/invoice/invoice-edit/InvoiceEdit.vue"),
  },
];
