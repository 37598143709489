import service from "@/service";
import axios from "@axios";
import { responseInterceptor } from "@/libs/axios";
import { secretKey } from "@/auth/secretKey";

const API = process.env.VUE_APP_API_URL || "https://einsteinlearningplus.com";

// Module Vuex akun
export default {
  namespaced: true,
  state: {
    datas: [],
    token: null,
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data;
    },
    SET_BEARER(state, accessToken) {
      axios.defaults.headers.common["Author"] = `Bearer ${accessToken}`;
    },
  },
  actions: {
    async index({ commit }, params) {
      try {
        let config = {
          method: "get",
          url: `${API}/notification`,
          params,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await axios.get(config.url, {
          params: config.params,
          headers: config.headers,
          skipInterceptors: [responseInterceptor],
        });
        // request(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async CRUTanda({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/notification/mark-as-read",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async DTanda({ commit }, payload) {
      try {
        let config = {
          method: "post",
          url: "/notification/delete",
          data: payload,
          headers: {
            secret: secretKey,
            Author: `bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await service.sendRequest(config);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
  },
};
